import { Component } from '@angular/core';

import { initSidenav } from './sidenav-init.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {
    ngAfterViewInit() {
        initSidenav();
    }
}
