export function initSidenav() {
    document.addEventListener('DOMContentLoaded', function() {
        var sidenavElems = document.querySelectorAll('.sidenav');
        var dropdownElems = document.querySelectorAll('.dropdown-trigger');
        var options = {};

        var sidenavInstances = M.Sidenav.init(sidenavElems, options);
        var dropdownInstances = M.Dropdown.init(dropdownElems, options);
    });
}
